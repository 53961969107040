import React from 'react'
import { Helmet } from 'react-helmet'

import Layout from '../components/layout'
import Footer from '../components/Footer'

import HeaderPage from '../components/HeaderPage'
import PublicationsList from '../components/PublicationsList'

const PublicationsPage = () => (
  <div>
    <Helmet>
      <title>Patents and Publications — Vishal Berry</title>
      <meta name="description" content="Patents and publications by Vishal Berry" />
      <html lang="en" />
    </Helmet>

    <Layout>
        <div className="content">
          <div className="inner">
            <HeaderPage />
            <PublicationsList />
          </div>
        </div>
      <Footer />
    </Layout>
  </div>
)

export default PublicationsPage
